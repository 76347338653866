<template>
  <section class="relative z-20 -mt-6 text-white bg-blue-900">
    <div class="container py-12 mx-auto">
      <div class="flex justify-center mb-12">
        <ul class="flex">
          <li v-for="(link, social) in language?.socials||{}" :key="social">
            <a :href="link" target="_blank" rel="noopener nofollow" :aria-label="social" class="flex justify-center items-center mx-3 w-12 h-12 rounded border border-white hover:border-gray-700">
              <img
                :src="`${$paths.assetsUrl}images/${social}.svg`"
                :alt="`Logo: ${social}`"
                width="36"
                height="36"
                class="w-9 h-9"
                loading="lazy"
              >
            </a>
          </li>
        </ul>
      </div>
      <div class="flex flex-col justify-center leading-loose sm:flex-row">
        <p class="mx-4 font-bold">
          &copy; Parkos
        </p>
        <a v-if="termsConditionsPageContent.url" :href="termsConditionsPageContent.url" class="mx-4 text-white hover:text-white">{{ termsConditionsPageContent.title }}</a>
        <a v-if="privacyPolicyPageContent.url" :href="privacyPolicyPageContent.url" class="mx-4 text-white hover:text-white">{{ privacyPolicyPageContent.title }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { getInstance } from '~/services/bffService';

export default {
  data() {
    return {
      language: null,
      termsConditionsContent: null,
      privacyPolicyContent: null,
    };
  },

  async fetch() {
    const bff = getInstance('parkos', this.$config);
    const languages = await bff.getLanguages();
    const currentLanguage = await Array.prototype.find.call(languages, (language) => language.domain === this.$paths.host);

    if (!currentLanguage?.socials?.facebook) {
      delete currentLanguage.socials.facebook;
    }

    if (!currentLanguage?.socials?.twitter) {
      delete currentLanguage.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!currentLanguage?.socials?.youtube) {
      currentLanguage.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    this.language = currentLanguage;

    this.termsConditionsContent = await bff.getPageContent('terms-conditions');
    this.privacyPolicyContent = await bff.getPageContent('privacy-policy');
  },

  computed: {
    termsConditionsPageContent() {
      if (this.termsConditionsContent && this.language && this.language.lang) {
        const currentContent = this.termsConditionsContent[this.language.lang];
        return { title: currentContent.title, url: `${this.$paths.url()}${currentContent.slug}.html` };
      }

      return { title: '', url: '' };
    },
    privacyPolicyPageContent() {
      if (this.privacyPolicyContent && this.language && this.language.lang) {
        const currentContent = this.privacyPolicyContent[this.language.lang];
        return { title: currentContent.title, url: `${this.$paths.url()}${currentContent.slug}.html` };
      }

      return { title: '', url: '' };
    },
  },
};
</script>
