import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const page = (path) => () => import(`./pages/${path}.vue`).then((m) => m.default || m);

const routes = [
  {
    path: '/',
    name: 'index',
    props: false,
    component: page('index'),
  },
  {
    path: '/:airport/:merchant.html',
    name: 'merchant',
    props: true,
    component: page('_airport/_merchant'),
  },
];
// eslint-disable-next-line import/prefer-default-export
export function createRouter() {
  return new VueRouter({
    mode: 'history',
    routes,
  });
}
