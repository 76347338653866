<template>
 <nuxt-img format="webp" :src="`/_nuxt_merchant_page/assets/img/social/logo-youtube.png`" loading="lazy" alt="youtube" />
</template>

<script>
export default {

};
</script>

<style>

</style>
