import axios from 'axios';

class ReviewsService {
  constructor($config) {
    const baseURL = ($config.REVIEWS_BASE_URL || '').replace(/\/$/, '');
    this.axiosInstance = axios.create({ baseURL, timeout: 500 });

    this.axiosInstance.interceptors.request.use((request) => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      setTimeout(() => {
        source.cancel(`ReviewsService is timed out: ${request.url}`);
      }, 500);
      request.cancelToken = source.token;
      return request;
    }, (error) => {
      console.log('Reviews Request Error: ', JSON.stringify(error)); return error;
    });
    this.axiosInstance.interceptors.response.use((response) => response, (error) => { console.log('Reviews Response error: ', JSON.stringify(error)); return error; });
  }

  getReviewsFilter(filter, property, params) {
    filter = Array.isArray(filter) ? filter : [filter];

    params.append(`${property}[in]`, filter.join(','));

    return params;
  }

  async getReviewsSummaries({ language_id, merchant_id, parking_type }, options = {}) {
    let path = '/v1/review-summaries';
    let params = new URLSearchParams();

    if (language_id) {
      params = this.getReviewsFilter(language_id, 'language.id', params);
    }

    if (merchant_id) {
      params = this.getReviewsFilter(merchant_id, 'merchant.id', params);
    }

    if (parking_type) {
      params = this.getReviewsFilter(parking_type, 'merchant.parkingTypes.type', params);
    }

    if (params.toString()) path += `?${params.toString()}`;

    return this.axiosInstance.get(path, options);
  }
}

const reviewsInstances = {};

function getInstance(name, config) {
  if (!(name in reviewsInstances)) {
    reviewsInstances[name] = new ReviewsService(config);
  }

  return reviewsInstances[name];
}

export { getInstance };
