export const actions = {
  toggle() {
  },
};

export const mutations = {
  update(state, keyValue) {
    state[keyValue.key] = keyValue.value;
  },
  updateFacetedRating(state, keyValue) {
    state.facetedRating[keyValue.key] = keyValue.value;
  },
};

export const state = () => ({
  type: null,
  language: null,
  score: null,
  page: 1,
  count: 0,
  facetedRating: {
    [null]: 0,
    rating1: 0,
    rating2: 0,
    rating3: 0,
    rating4: 0,
    rating5: 0,
  },
});
