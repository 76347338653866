<template>
  <div
    role="menu"
    tabindex="0"
    class="block md:inline-block md:static"
    :class="{ relative: !externalShow }"
    @focusin.stop.prevent="expandHover"
    @focusout.stop.prevent="collapseHover"
    @mouseenter.stop.prevent="expandHover"
    @mouseleave.stop.prevent="collapseHover"
  >
    <div
      ref="airportsLabel"
      role="button"
      tabindex="0"
      class="airports-label"
      :class="{
        'airports-label--collapse': !show,
        'hidden md:block': externalShow,
      }"
      @keypress.stop.prevent="toggle"
      @click.stop.prevent="toggle"
    >
      <div class="items-center airports-label__default">
        {{ $i18n('general.airports') }}
        <IconCaretRight class="ml-auto text-black-alt-500 md:hidden" />
        <div class="hidden ml-1 md:block">
          <div class="caret" />
        </div>
      </div>
      <div class="md:hidden flex items-center text-base airports-label__back">
        <IconCaretRight class="mr-3 text-black-alt-500 rotate-180" />
        {{ $i18n('templates.go-to-home') }}
      </div>
    </div>
    <div
      v-show="show || externalShow"
      ref="scroller"
      v-click-outside="hide"
      class="mega-menu text-black absolute inset-x-0 cursor-default z-50 md:top-20 md:shadow-section"
      :class="{
        'mega-menu--half-scrolled': halfScrolled,
        'inset-0 md:inset-y-auto bg-white md:hidden': externalShow,
      }"
    >
      <div class="text-base" :class="{ 'filtering-country': filteringCountry }">
        <div
          v-if="unfilteredCountries.length > 1"
          class="hidden bg-silver-100 md:block"
        >
          <div class="pb-3 md:container md:px-10 md:pt-7">
            <SearchInput
              class="relative mega-menu__search"
              :expression="searchExpression"
              @expressionChanged="searchExpression = $event"
            />
            <div
              v-if="!externalShow && countries.length"
              class="flex flex-wrap justify-start py-4 mt-3 font-bold select-none md:flex mega-menu__countries -mx-5"
            >
              <div
                role="button"
                tabindex="0"
                class="px-5 text-black-alt-800 cursor-pointer mega-menu__countries__country"
                :class="{
                  'mega-menu__countries__country--highlight': !filteringCountry,
                }"
                @keypress.stop.prevent="filteringCountry = null"
                @click.stop.prevent="filteringCountry = null"
              >
                {{ $i18n('general.all-countries') }}
              </div>
              <div v-for="country in countries" :key="country">
                <div
                  v-if="countries.length > 1"
                  role="button"
                  tabindex="0"
                  class="px-5 text-black-alt-800 cursor-pointer mega-menu__countries__country"
                  :class="{
                    'mega-menu__countries__country--highlight':
                      filteringCountry === country,
                  }"
                  @keypress.stop.prevent="filterCountry(country)"
                  @click.stop.prevent="filterCountry(country)"
                >
                  {{ country }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-white select-none"
          :class="countries.length > 1 ? 'md:pt-3 md:pb-10 ' : 'md:py-10'"
        >
          <div
            v-if="!countries.length"
            class="flex place-content-center px-7 mt-8 text-center md:px-10"
          >
            {{ $i18n('customer.no-results') }}
          </div>
          <div
            class="place-content-between md:container md:gap-y-4 md:px-10 mega-menu__grid"
            :class="{
              'md:grid': !filteringCountry,
              'md:grid-cols-4': !filteringCountry && !isUSDomain,
              'md:grid-cols-1': !filteringCountry && isUSDomain,
            }"
          >
            <template v-for="country in countries">
              <template v-if="countryAirportsFiltered[country].length">
                <div
                  :key="country"
                  class="mega-menu__grid__country"
                  :class="{
                    grid: filteringCountry || isUSDomain,
                    'md:grid-cols-1': columns === 1 && !isUSDomain,
                    'md:grid-cols-2': columns === 2 && !isUSDomain,
                    'md:grid-cols-3': columns === 3 || isUSDomain,
                    'md:grid-cols-4': columns === 4 && !isUSDomain,
                  }"
                >
                  <div
                    v-if="countries.length > 1"
                    class="mega-menu__grid__country__title"
                    :class="{
                      'mega-menu__grid__country__title--full':
                        filteringCountry || isUSDomain,
                    }"
                  >
                    {{ country }}
                  </div>
                  <template v-for="airport in countryAirportsFiltered[country]">
                    <div
                      :key="airport.id"
                      class="md:pr-2 md:my-1 mega-menu__grid__airport"
                    >
                      <a
                        :href="`/${airport.slug}/`"
                        class="flex items-center py-3 px-5 hover:text-orange-500 hover:no-underline border-b border-athens md:p-0 md:border-none"
                        :class="{
                          'text-orange-500':
                            selectedAirportByPicker.id === airport.id,
                          'text-black-alt-500':
                            selectedAirportByPicker.id !== airport.id,
                        }"
                        @click="clear"
                      >
                        <div
                          class="w-full pr-2 md:pr-0 whitespace-nowrap overflow-hidden text-ellipsis overflow-ellipsis"
                        >
                          {{ airport.name }}
                        </div>
                        <IconCaretRight
                          class="text-black-alt-500 md:hidden ml-auto transform scale-75"
                        />
                      </a>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import SearchInput from '~/components/header/SearchInput.vue';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    IconCaretRight,
    SearchInput,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    expression: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      show: false,
      searchExpression: '',
      halfScrolled: false,
      filteringCountry: null,
      externalShow: false,
      unfilteredCountries: [],
    };
  },
  computed: {
    selectedAirportByPicker() {
      return this.$store.state.airportPicker?.airport || {};
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
    language() {
      return this.$store.state.language;
    },
    columns() {
      if (
        !this.configuration
        || !this.configuration[this.language.country.name]
        || !this.configuration[this.language.country.name].columns
      ) {
        return 4;
      }
      return this.configuration[this.language.country.name].columns;
    },
    configuration() {
      return this.$store.state.headerAirportsConfiguration;
    },
    airports() {
      return this.$store.state.airports;
    },
    countryAirportsFiltered() {
      const { airports } = this;
      const map = {};
      airports.forEach((airport) => {
        if (!map[airport.country.name]) {
          map[airport.country.name] = [];
        }

        const search = [
          airport.name,
          airport.iata_code,
          airport.address.city,
          airport.devtitle,
          airport.internationalAirportName,
          airport.slug,
        ]
          .filter(Boolean)
          .map((value) => value.toLocaleLowerCase());

        const isEmpty = !this.searchExpression || this.searchExpression.trim() === '';

        if (
          isEmpty
          || search.some((value) => value.includes(this.searchExpression.toLowerCase()))
        ) {
          map[airport.country.name].push(airport);
        }
      });
      // eslint-disable-next-line no-restricted-syntax
      for (const key in map) {
        if (!map[key] || !map[key].length) {
          delete map[key];
        } else {
          map[key].sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      const filterCountry = this.filteringCountry;
      if (filterCountry) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in map) {
          if (filterCountry !== key) {
            map[key] = [];
          }
        }
      }
      return map;
    },
    countries() {
      const countries = Object.keys(this.countryAirportsFiltered);
      countries.forEach((country, index) => {
        countries[index] = {
          country,
          count: this.airports.filter((f) => f.country.name === country).length,
        };
      });
      return countries
        .sort((a, b) => {
          const country = this.language.native_name.replace(
            /(\s*)?\((.+)?\)(\s*)?/,
            '',
          );
          if (country === a.country) {
            return Number.MIN_SAFE_INTEGER;
          }
          if (country === b.country) {
            return Number.MAX_SAFE_INTEGER;
          }
          return b.count - a.count;
        })
        .map((c) => c.country);
    },
  },
  watch: {
    open(value) {
      this.externalShow = false;
      this.show = value;
    },
    expression(value) {
      this.searchExpression = value;
      if (this.searchExpression && this.searchExpression !== '') {
        this.externalShow = true;
      } else {
        this.externalShow = false;
      }
    },
    show(value) {
      if (value) {
        this.$emit('toggled', true);
      } else {
        this.$emit('toggled', false);
      }
    },
  },
  created() {
    this.unfilteredCountries = Object.keys(this.countryAirportsFiltered);
  },
  methods: {
    collapseHover(e) {
      if (this.$screens({ md: false }, true)) {
        return;
      }
      if (e?.srcElement?.classList?.contains('mega-menu__countries__country')
      || e?.srcElement?.classList?.contains('search-expression__clear')
      || e?.srcElement?.classList?.contains('mega-menu__search__input')
      || e?.relatedTarget?.classList?.contains('mega-menu__countries__country')
      || e?.relatedTarget?.classList?.contains('search-expression__clear')
      || e?.relatedTarget?.classList?.contains('mega-menu__search__input')
      || e?.relatedTarget?.role === 'menu'
      ) {
        return;
      }
      this.show = false;
    },
    expandHover() {
      if (this.$screens({ md: false }, true)) {
        return;
      }
      this.show = true;
    },
    hide(event) {
      if (!this.show || this.$refs.airportsLabel.contains(event.target)) {
        return;
      }
      if (window && window.innerWidth < 770) {
        return;
      }
      this.show = false;
      this.clear();
      this.$emit('toggled', this.show);
    },
    toggle() {
      if (this.$screens({ md: true }, false)) {
        return;
      }
      this.show = !this.show;
      if (!this.show) {
        this.clear();
      }
      this.$emit('toggled', this.show);
    },
    filterCountry(country) {
      if (this.filteringCountry === country) {
        this.filteringCountry = null;
        return;
      }
      this.filteringCountry = country;
    },
    getColumns(countryName) {
      if (!this.configuration[countryName]) {
        if (!this.configuration.default) {
          return 4;
        }
        return this.configuration.default.columns;
      }
      return this.configuration[countryName].columns;
    },
    clear(keepShowing) {
      if (!keepShowing) {
        this.show = false;
      }
      this.filteringCountry = null;
      this.searchExpression = '';
    },
  },
};
</script>

<style lang="scss">
.airports-label {
  $self: &;

  @apply border-athens-100 border-b md:border-none md:inline-block cursor-pointer px-6 pt-6 md:px-3 md:pt-2 pb-6 md:pb-2 md:pb-12 md:-mb-12 font-sans;

  &:hover {
    #{$self}__default {
      @media (min-width: 1080px) {
        @apply underline;
      }
    }
  }
  &--collapse {
    #{$self}__back {
      @apply hidden;
    }
    #{$self}__default {
      @apply flex;
    }
  }
  &__back {
    @apply flex md:hidden;
  }
  &__default {
    @apply hidden md:flex;
  }
}

.mega-menu {
  @apply overflow-y-scroll md:overflow-y-visible;

  &__countries {
    &__country {
      &--highlight {
        @apply text-orange-500;
      }
      &--understate {
        @apply text-gray-500;
      }
    }
  }
  &__grid {
    &__country {
      &__title {
        @apply p-5 md:px-0 md:py-0 bg-silver-100 md:bg-transparent font-bold text-xl md:text-base text-gray-400 md:pt-2;
        &--full {
          grid-column: 1/-1;
        }
      }
    }
  }
}
</style>
