<template>
  <nuxt-img
    format="webp"
    src="~/static/general/linkedin.svg"
    alt="linkedin"
    loading="lazy"
    width="auto"
    height="auto"
  />
</template>

<script>
export default {};
</script>

<style></style>
