<template>
  <nuxt-img format="webp" src="~/static/general/pinterest.svg" loading="lazy" alt="pinterest" />
</template>

<script>
export default {

};
</script>

 <style>

 </style>
