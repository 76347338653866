const hashCode = (str) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    hash = Math.imul(31, hash) + str.charCodeAt(i) | 0;
  }
  return `${hash}`;
};

export const state = () => ({
  parkingsOffers: {},
  clickedListing: null,
  selectedParking: null,
  offerKeys: {},
  isLoading: true,
});

export const actions = {
  getOffers(_, {
    airport,
    arrivalTime,
    departureTime,
    arrival,
    departure,
    lang,
    parkingIds,
  }) {
    return this.$axios({
      method: 'get',
      baseURL: this.$config.BFF_BASE_URL,
      url: 'offers',
      params: {
        location: airport.slug,
        arrivalTime,
        departureTime,
        arrival,
        departure,
        lang,
        parkingIds: [parkingIds],
        blink: true,
      },
    });
  },
  async setParkings({ state, dispatch, commit }, { parkingsOffers }) {
    commit('SET_PARKINGS', parkingsOffers);
    if (state.parkingsOffers?.available) {
      const size = Object.keys(state.parkingsOffers?.available).length;
      if (size) {
        Object.keys(state.parkingsOffers?.available).map(async (offer) => {
          await dispatch('getVForKey', { offer: state.parkingsOffers?.available[offer] }).then((id) => {
            state.offerKeys[offer] = id;
          });
        });
      } else {
        state.offerKeys = [];
      }
    }
  },
  async setSelectedParking({ dispatch, commit, state }, { initial, initialParking }) {
    if (initial && initialParking) {
      commit('SET_SELECTED_PARKING', initialParking);
    } else if (state.parkingsOffers && state.parkingsOffers?.available && Object.values(state.parkingsOffers?.available).length) {
      const valletParkingFound = Object.values(state.parkingsOffers?.available).find((parking) => parking.parking_type === 'valet');
      if (valletParkingFound) {
        commit('SET_SELECTED_PARKING', valletParkingFound);
      } else {
        commit('SET_SELECTED_PARKING', Object.values(state.parkingsOffers?.available)[0]);
      }
      const clickedListing = await dispatch('getVForKey', { offer: state.selectedParking });
      if (state.clickedListing !== clickedListing) {
        commit('SET_CLICKED_LISTING', clickedListing);
        this.$router.push({ path: '', query: { clickedListing } });
      }
    } else {
      commit('SET_SELECTED_PARKING', null);
      commit('SET_CLICKED_LISTING', null);
    }
  },
  async selectParking({ commit, dispatch, state }, { id }) {
    if (state.clickedListing === id) return;
    let foundParking = null;
    const bar = new Promise((resolve, reject) => {
      Object.values(state.parkingsOffers?.available).forEach(async(parking, index, array) => {
        const generatedId = await dispatch('getVForKey', { offer: parking });
        if (generatedId === id) {
          foundParking = parking;
          resolve();
        }
        if (index === array.length - 1) resolve();
      });
    });
    bar.then(async() => {
      if (foundParking) {
        commit('SET_CLICKED_LISTING', id);
        this.$router.push({ path: '', query: { clickedListing: id } });
        await dispatch('setSelectedParking', { initial: true, initialParking: foundParking });
      } else {
        await dispatch('setSelectedParking', { initial: true });
      }
    });
  },
  getParkingType(_, { parkingType, roof, short = false }) {
    if (short) {
      if (parkingType === 'shuttle') {
        return roof ? 'si' : 'so';
      }
      if (parkingType === 'valet') {
        return roof ? 'vi' : 'vo';
      }
    }

    if (parkingType === 'shuttle') {
      return roof ? 'indoor' : 'outdoor';
    }
    if (parkingType === 'valet') {
      return roof ? 'valetindoor' : 'valet';
    }
  },
  async getVForKey({ dispatch }, { offer }) {
    if (!offer) {
      return '';
    }

    const parkingType = await dispatch('getParkingType', {
      parkingType: offer.parking_type,
      roof: offer.roof,
    });
    return hashCode(`${offer.merchant.id}-${offer.price}-${parkingType}-${offer.sort_reviews}`);
  },
};

export const mutations = {
  SET_PARKINGS(state, parkingsOffers) {
    state.parkingsOffers = parkingsOffers;
  },
  SET_SELECTED_PARKING(state, selectedParking) {
    state.selectedParking = selectedParking;
  },
  SET_CLICKED_LISTING(state, clickedListing) {
    state.clickedListing = clickedListing;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};
