<template>
  <div
    class="w-full bg-white inset-0"
    :class="{ 'absolute overflow-y-scroll': expanded }"
  >
    <div
      v-if="expanded"
      role="button"
      tabindex="0"
      class="p-6 flex items-center text-base"
      @keypress.stop.prevent="collapse"
      @click.stop.prevent="collapse"
    >
      <IconCaretRight class="text-black-alt-500 rotate-180 transform mr-3" />
      {{ $i18n('templates.go-to-home') }}
    </div>
    <div
      role="button"
      tabindex="0"
      class="flex items-center w-full p-6 bg-silver-100"
      @keypress.stop.prevent="expand"
      @click.stop.prevent="expand"
    >
      <Flag :lang="language.lang" />
      {{ language.name }}
      <template v-if="!expanded">
        <IconCaretRight
          class="text-black-alt-500 ml-auto transform"
          :class="{ 'rotate-90': expanded }"
        />
      </template>
      <template v-else>
        <IconCheckmark class="text-blue-600 ml-4" />
      </template>
    </div>
    <div v-if="expanded" class="w-full">
      <ul>
        <li
          v-for="item in herflangs"
          :key="`lang-${item.lang}`"
          v-show="item.lang !== language.lang"
        >
          <a
            :href="`//${item.domain}/${item.slug}/${merchantSlug}.html`"
            class="block px-6 py-3 border-b border-athens text-base text-gray-600 whitespace-nowrap hover:text-black hover:no-underline flex items-center"
          >
            <Flag :lang="item.lang" />
            {{ languageByLang(item.lang) }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Flag from '~/components/icons/Flag.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import IconCheckmark from '~/components/icons/IconCheckmark.vue';

const {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} = require('body-scroll-lock');

export default {
  components: {
    Flag,
    IconCaretRight,
    IconCheckmark,
  },
  props: {
    expanded: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    merchantSlug() {
      return this.$store.state.merchantSlug;
    },
    language() {
      return this.$store.state.language;
    },
    herflangs() {
      return this.$store.state.herflangs || [];
    },
    languages() {
      return this.$store.state.languages;
    },
  },
  methods: {
    languageByLang(lang) {
      return this.languages.find((e) => e.lang === lang)?.native_name;
    },
    expand() {
      this.$emit('toggled', true);
      this.$nextTick(() => {
        disableBodyScroll(this.$el);
      });
    },
    collapse() {
      clearAllBodyScrollLocks();
      this.$emit('toggled', false);
    },
  },
};
</script>

<style></style>
