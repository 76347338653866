import Vue from 'vue';

Vue.prototype.$locale = window.locale;
Vue.prototype.$getParkingType = (parkingType, roof, short = false) => {
  if (short) {
    if (parkingType === 'shuttle') {
      return roof ? 'si' : 'so';
    }
    if (parkingType === 'valet') {
      return roof ? 'vi' : 'vo';
    }
  }

  if (parkingType === 'shuttle') {
    return roof ? 'indoor' : 'outdoor';
  }
  if (parkingType === 'valet') {
    return roof ? 'valetindoor' : 'valet';
  }
  return '';
};
