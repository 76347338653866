<template>
  <nuxt-img
    format="webp"
    src="~/static/general/checkmark.svg"
    width="auto"
    height="auto"
    loading="lazy"
    alt="checkmark"
  />
</template>

<script>
export default {};
</script>

<style></style>
