/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { getFilteredAggregation } from '~/helpers/reviews';
import { getInstance as getBFFInstance } from '~/services/bffService';
import { getInstance as getReviewsInstance } from '~/services/reviewsService';

function assignDataForDABucket(state, bucketState) {
  // domain
  bucketState.domain.country = state.language.country.name;
  bucketState.domain.country_code = state.language.country.code;
  bucketState.domain.language = state.language.lang;
  bucketState.domain.name = state.language.domain;
  //
  bucketState.airport.name = state.airport.commonName;
  bucketState.airport.id = state.airport.id;
  bucketState.airport.slug = state.airport.slug;
  bucketState.airport.devtitle = state.airport.devtitle;
  bucketState.airport.internationalAirportName = state.airport.internationalAirportName;
  //
  bucketState.review_score = state.reviewsSummary?.summary?.rating;
  bucketState.review_amount = state.reviewsSummary?.summary?.total;
  //
  bucketState.currency = state.language.currency.iso_code;
  //
  bucketState.item_category = `${state.language.country.name} - ${state.airport.commonName}`;
  bucketState.item_list_name = state.airport.devtitle;
  bucketState.category = `${state.language.country.name}/${state.airport.commonName}`;
  //
  bucketState.merchant.id = state.merchant.id;
  bucketState.merchant.name = state.merchant.name;
  bucketState.merchant.slug = state.merchant.slug;
}

export const state = () => ({
  airportSlug: null,
  merchantSlug: null,
  languages: [],
  language: {},
  airports: [],
  airport: {},
  merchants: [],
  merchant: {},
  reviews: {},
  reviewsRatingsOptions: ['eq:10', 'eq:8', 'eq:6', 'eq:4', 'eq:2'],
  parkingTypeOptions: {
    valet: 0,
    valetindoor: 0,
    indoor: 0,
    outdoor: 0,
  },
  reviewsRatings: {},
  reviewsSummary: {},
  dates: {
    departure: dayjs(new Date())
      .add(14, 'days')
      .format('YYYY-MM-DD'),
    arrival: dayjs(new Date())
      .add(7, 'days')
      .format('YYYY-MM-DD'),
    departureTime: '12:00',
    arrivalTime: '12:00',
  },
  pageContent: {},
  pageTemplate: {},
  metaPages: {},
  herflangs: [],
  searchResults: [],
  alternativeMerchant: [],
  pricePerDay: null,
  zendeskEnabled: false,
  flags: { experiments: {}, features: {} },
});

export const mutations = {
  ChangeData(state, payload) {
    state.dates = payload;
  },
  SearchResults(state, payload) {
    state.searchResults = payload;
  },
  AlternativeMerchant(state, payload) {
    state.alternativeMerchant = payload;
  },
  changeReviewsRatings(state, payload) {
    state.reviewsRatings = payload;
  },
};

export const getters = {
  isExperimentEnabled: (state) => (name) => {
    const { features } = state.flags;

    return !!features?.[name];
  },
  experimentVariant: (state) => (name) => state.flags.features?.[name],
};

export const actions = {
  experimentVariant({ state, commit }, { name = '', condition = true }) {
    if (!condition) {
      return undefined;
    }
    commit('experiments/addExperiment', {
      name,
      variant: state.flags.features?.[name],
    });
    return state.flags.features?.[name];
  },
  setExperiments({ state }, req) {
    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',')
          .forEach((element) => {
            const [elKey, elValue] = element.split('=');
            result[elKey] = elValue;
          });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error('An error occurred while processing request headers', { error });
    }
  },
  async nuxtServerInit({ state, getters, dispatch }, {
    $sentry, req, $paths, route,
  }) {
    console.log('[nuxtServerInit] start ', req.originalUrl);

    const BFF = getBFFInstance('parkos', this.$config);
    const reviewsService = getReviewsInstance('parkos', this.$config);

    dispatch('setExperiments', req);

    // Initiate content
    const { host } = $paths;
    const airportSlug = route.params.airport;
    const merchantSlug = route.params.merchant;
    if (!airportSlug || !merchantSlug) {
      return null;
    }

    state.airportSlug = airportSlug;
    state.merchantSlug = merchantSlug;

    state.languages = await BFF.getLanguages();

    state.language = Array.prototype.find.call(
      state.languages,
      (language) => language.domain === host,
    );
    state.merchants = await BFF.getAirportParkings(
      airportSlug,
      state.language.lang,
    );
    const merchant = Array.prototype.find.call(
      state.merchants,
      (merchant) => merchant.slug === merchantSlug,
    );
    if (!merchant?.id) {
      return null;
    }

    const { lang } = state.language;
    const promises = [
      BFF.getTranslations(lang),
      BFF.getAirports(lang),
      BFF.getAirport(state.airportSlug, lang),
      BFF.getAirportData(state.airportSlug, lang),
      BFF.getMerchantContent(merchant.id),
      BFF.getMerchantReviewsV2(
        merchant.id,
        undefined,
        undefined,
        0,
        30,
      ),
      ...state.reviewsRatingsOptions.map((score) => BFF.getMerchantReviewsV2(
        merchant.id,
        undefined,
        undefined,
        0,
        30,
        score,
      )),
      ...Object.keys(state.parkingTypeOptions).map((type) => BFF.getMerchantReviewsV2(
        merchant.id,
        undefined,
        type,
        0,
        30,
      )),
      BFF.getHerflangs(merchant.id),
      BFF.getPageContent('faq'),
      BFF.getPageContent('terms-conditions'),
      BFF.getPageContent('privacy-policy'),
      BFF.getPageContent('about-us'),
      BFF.locationSearch({
        lang,
        location: airportSlug,
        ...state.dates,
      }),
      ...[
        'footer_airports',
        'footer_information',
        'footer_about',
        'footer_icons',
      ].map((_slug) => BFF.getPageTemplate(_slug, lang)),
      BFF.getService('zendesk_whitelist'),
    ];
    const [
      translations,
      airports,
      airport,
      airportData,
      merchantData,
      reviews,
      reviewsRatings1,
      reviewsRatings2,
      reviewsRatings3,
      reviewsRatings4,
      reviewsRatings5,
      valetReviews,
      valetIndoorReviews,
      shuttleIndoorReviews,
      shuttleOutdoorReviews,
      herflangs,
      faqMeta,
      termsContent,
      policyContent,
      aboutContent,
      locationSearch,
      footer_airports,
      footer_information,
      footer_about,
      footer_icons,
      zendeskWhitelist,

    ] = await Promise.all(promises);

    try {
      const reviewsSummary = await reviewsService.getReviewsSummaries({ merchant_id: [merchant?.id] });
      state.reviewsSummary = reviewsSummary.data;
    } catch (e) {
      $sentry.captureException(e);
      // eslint-disable-next-line no-console
      console.error('Failed to fetch Review Summary', e);
      state.reviewsSummary = null;
    }

    state.parkingTypeOptions = {
      valet: valetReviews?.pagination?.total?.count,
      valetindoor: valetIndoorReviews?.pagination?.total?.count,
      indoor: shuttleIndoorReviews?.pagination?.total?.count,
      outdoor: shuttleOutdoorReviews?.pagination?.total?.count,
    };

    state.translations = translations;
    state.airports = airports;
    state.airport = airport;
    state.airportData = airportData;
    state.merchant = merchantData;
    state.reviewsRatings = {
      [state.reviewsRatingsOptions[0]]: reviewsRatings1?.pagination?.total?.count,
      [state.reviewsRatingsOptions[1]]: reviewsRatings2?.pagination?.total?.count,
      [state.reviewsRatingsOptions[2]]: reviewsRatings3?.pagination?.total?.count,
      [state.reviewsRatingsOptions[3]]: reviewsRatings4?.pagination?.total?.count,
      [state.reviewsRatingsOptions[4]]: reviewsRatings5?.pagination?.total?.count,
    };
    state.reviews = {
      reviews: reviews?.reviews?.map((review) => ({
        id: review.id,
        language_id: review.language.id,
        author: review.customerName,
        message: review.text,
        rating: review.rating,
        numberOfPersons: review.reservation.numberOfPersons,
        reviewDate: review.createdAt,
        parkingDate: review.reservation.arrivalDate,
        departure: review.reservation.departureDate,
        parking_type: review.merchant.parkingTypes[0].types,
        reply_message: review.companyReply ? review.companyReply.text : null,
        is_parkos_reply: review.isParkosReply,
      })),
      pagination: reviews?.pagination,
    };
    state.herflangs = herflangs;
    state.metaPages = {
      faqMeta: faqMeta[lang],
    };
    state.pageContent = {
      termsConditionsContent: termsContent[lang],
      privacyPolicyContent:
        policyContent[lang],
      aboutPageContent: aboutContent[lang],
    };
    state.airportData.content = { [lang]: state.airportData.content[lang] };
    state.merchant.content = { [lang]: state.merchant.content[lang] };
    state.airports = airports.filter(
      (airport) => !!state.language?.status
        && (!!state.language.is_minimal_product || airport.parking_count > 0),
    );
    state.pageTemplate = {
      footer_airports,
      footer_information,
      footer_about,
      footer_icons,
    };
    state.zendeskEnabled = zendeskWhitelist || zendeskWhitelist.includes(host);

    if (state.reviews && state.reviews.groups) {
      state.reviews.all = getFilteredAggregation(state.reviews.groups, undefined);
    }

    const locationSearchResult = locationSearch;
    if (locationSearchResult && locationSearchResult.available) {
      const values = Object.values(
        typeof locationSearchResult.available === 'object'
          ? locationSearchResult.available
          : {},
      );
      const [merchantPrice] = values.filter(
        (d) => d.merchant.id === state.merchant.id,
      );
      if (merchantPrice) {
        state.pricePerDay = merchantPrice.price / (merchantPrice.days || 8);
      }
    }
    assignDataForDABucket(state, state.bucket);

    console.log('[nuxtServerInit] end ', req.originalUrl);

    return null;
  },
};
