<template>
  <nuxt-img
    format="webp"
    :src="`/_nuxt_merchant_page/assets/img/social/logo-instagram.png`"
    loading="lazy"
    alt="instagram"
    width="auto"
    height="auto"
  />
</template>

<script>
export default {};
</script>

<style></style>
