<template>
  <div class="overflow-x-hidden" :class="{ fixed: false }">
    <Header
      @toggle="navToggle"
    />
    <div class="flex flex-col justify-center my-6 mx-auto align-center">
      <h1 v-if="error.statusCode === 404" class="text-3xl">
        Page not found
      </h1>
      <h1 v-else class="text-3xl">
        {{ error.message }}
      </h1>
      <NuxtLink to="/">
        Home page
      </NuxtLink>
    </div>
    <Footer />
    <LegalFooter />
  </div>
</template>

<script>
import Header from '~/components/header/Header.vue';
import Footer from '../components/core/shared/footer/Footer.vue';
import LegalFooter from '../components/core/shared/footer/TheLegalFooter.vue';

export default {
  components: {
    Header,
    Footer,
    LegalFooter,
  },
  layout: 'error',
  props: ['error'], // you can set a custom layout for the error page
  data() {
    return {
      navShown: false,
      fixed: false,
    };
  },
  methods: {
    navToggle(value) {
      this.navShown = value;
    },
  },
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
};
</script>
