<template>
  <div class="relative py-10 text-white xs:py-20 support-wrapper">
    <div class="px-5 xs:container sm:px-10">
      <div class="sm:w-6/12">
        <div class="font-cocogoose text-base md:text-3xl">
          {{ $i18n('customer.we-support') }}
        </div>
        <div class="my-7 text-14 md:text-base">
          {{ $i18n('customer.our-experts-description') }}
        </div>

        <a
          class="text-14 md:text-base flex py-3 px-6 max-w-max font-bold text-center text-white hover:no-underline whitespace-nowrap bg-orange-500 hover:bg-blaze-orange-500 rounded-md cursor-pointer"
          :href="`https://parkos.zendesk.com/hc/${zenDeskLangCode}`"
        >
          {{ $i18n('customer.contact-us') }}
        </a>
      </div>
    </div>
    <div class="overflow-hidden absolute inset-0 -z-10 support-wrapper__image">
      <nuxt-img
        format="webp"
        src="/_nuxt_merchant_page/assets/img/footer-bg-new.jpg"
        width="auto"
        height="auto"
        loading="lazy"
        alt="PARKOS"
        class="object-cover object-left-top absolute inset-0 w-full max-w-none h-full md:object-top"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.state.language;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        const langCode = this.language.lang;
        if (langCode === 'en-eu') {
          return 'en-150';
        }
        return langCode;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
.support-wrapper {
  background-color: rgba(14, 16, 23, 0.6);

  &__image {
    transform: scaleX(-1);
  }
}
</style>
