<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <div class="flex flex-row">
    <a href="https://www.instagram.com/parkos.international/" target="_blank">
      <IconInstagram class="w-6 h-6 text-white fill-current" />
    </a>
    <a target="_blank" href="https://www.facebook.com/ParkosInternational/">
      <IconFacebook class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a href="https://www.pinterest.com/parkosinternational/" target="_blank">
      <IconPinterest class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a href="https://www.linkedin.com/company/parkoshq/" target="_blank">
      <IconLinkedin class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a target="_blank" href="https://www.youtube.com/c/ParkosInternational/">
      <IconYoutube class="w-6 h-6 text-white fill-current ml-3" />
    </a>
  </div>
</template>

<script>
import IconYoutube from '~/components/icons/IconYoutube.vue';
import IconFacebook from '~/components/icons/IconFacebook.vue';
import IconInstagram from '~/components/icons/IconInstagram.vue';
import IconPinterest from '~/components/icons/IconPinterest.vue';
import IconLinkedin from '~/components/icons/IconLinkedin.vue';

export default {
  components: {
    IconYoutube,
    IconFacebook,
    IconInstagram,
    IconPinterest,
    IconLinkedin,
  },
  computed: {
    language() {
      return this.$store?.state?.language;
    },
    twitter() {
      const { language } = this;
      return language?.socials?.twitter;
    },
    facebook() {
      const { language } = this;
      return language?.socials?.facebook;
    },
    youtube() {
      const { language } = this;
      return language?.socials?.youtube;
    },
  },
};
</script>

<style></style>
