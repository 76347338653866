export const reviewScoreFilter = Object.freeze({
  rating1: '1',
  rating2: '2',
  rating3: '3',
  rating4: '4',
  rating5: '5',
});

export function getRatingKeyFromValue(reviewScoreFilterValue) {
  switch (reviewScoreFilterValue) {
    case '1':
      return 'rating1';
    case '2':
      return 'rating2';
    case '3':
      return 'rating3';
    case '4':
      return 'rating4';
    case '5':
      return 'rating5';
    default:
      return null;
  }
}
export const parkingTypes = Object.freeze({
  indoor: 'indoor', outdoor: 'outdoor', valet: 'valet', valetindoor: 'valetindoor',
});

/* eslint-disable import/prefer-default-export */
export function aggregateResults(reviewsMetaObjectArray) {
  return reviewsMetaObjectArray.reduce((prev, current) => ({
    count: prev.count + current.count,
    score: (prev.count * prev.score + current.score * current.count) / (current.count + prev.count),
    friendliness: (prev.count * prev.friendliness + current.friendliness * current.count) / (current.count + prev.count),
    rating1: prev.rating1 + current.rating1,
    rating2: prev.rating2 + current.rating2,
    rating3: prev.rating3 + current.rating3,
    rating4: prev.rating4 + current.rating4,
    rating5: prev.rating5 + current.rating5,
  }), {
    count: 0, score: 0, rating1: 0, rating2: 0, rating3: 0, rating4: 0, rating5: 0, friendliness: 0,
  });
}

export function getLanguages(reviewsMetaObjectArray) {
  return reviewsMetaObjectArray.map((e) => ({ id: e.language_id, iso_code: e.lang_iso_code }));
}

export function getFilteredAggregation(reviewsMetaObjectArray, lang, parkingType) {
  let filtered = reviewsMetaObjectArray;

  if (lang) {
    filtered = filtered.filter((e) => e.lang_iso_code === lang);
  }
  if (parkingType) {
    filtered = filtered.filter((e) => e.parking_type === parkingType || (parkingType instanceof Array && parkingType.includes(e.parking_type)));
  }

  const aggregated = aggregateResults(filtered);
  return aggregated;
}

export function facetedSearchCount(reviewsMetaObjectArray, lang, parkingType, ratingLevel) {
  let filtered = reviewsMetaObjectArray.filter((e) => e.lang_iso_code === lang || !lang);
  filtered = filtered.filter((e) => !parkingType || parkingType === e.parking_type || (parkingType instanceof Array && parkingType.includes(e.parking_type)));
  let sum = 0;
  filtered.forEach((e) => { sum += e[!ratingLevel ? 'count' : ratingLevel]; });
  return sum;
}
