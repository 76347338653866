import { Context, Plugin } from '@nuxt/types';
import getInstance from '~/services/pbxService';

const pbxPlugin: Plugin = (context: Context, inject): void => {
  const baseURL = process.server ? context.$config.pbxBaseUrl : `${window.origin}/pbx`;
  const pbx = getInstance('default', baseURL);
  inject('pbx', pbx);
  context.app.$pbx = pbx;
};

export default pbxPlugin;
