/* eslint-disable no-console */
import axios from 'axios';
import { stringify } from 'querystring';

class PbxService {
  /** @type {string} */
  baseURL;

  /** @type {import('axios').AxiosInstance} */
  axiosInstance;

  /**
   * @param baseURL {string}
   */
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.axiosInstance = axios.create();

    this.axiosInstance.interceptors.request.use((request) => request, (error) => {
      console.log('PBX Request Error: ', JSON.stringify(error));
      return error;
    });
    this.axiosInstance.interceptors.response.use((response) => response, (error) => {
      console.log('PBX Response error: ', JSON.stringify(error));
      return error;
    });
  }

  generateQuery(path, query) {
    return `${this.baseURL.replace(/^\/|\/$/g, '')}/${path}/?${stringify(query, '&', '=', {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    })}`;
  }

  /**
   * @param id {number}
   * @param forDomain {string}
   * @returns {Promise<{translation:{review:string|null,reply:string|null}}>}
   */
  async translateReview(id, forDomain) {
    const url = this.generateQuery(`reviews/${id}/translate`, { forDomain });
    const { data } = await this.axiosInstance.get(url);

    return data;
  }
}

const pbxInstances = {};

function getPbxInstance(name, baseUrl) {
  pbxInstances[name] = new PbxService(baseUrl);
  return pbxInstances[name];
}

export default getPbxInstance;
