<template>
  <div class="overflow-x-hidden" :class="{ fixed: navShown }">
    <experiment-toggle name="airLoveInTheAirCampaign">
      <template #a />
      <template #b>
        <spring-campaign-banner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="merAutumnCampaign">
      <template #a />
      <template #b>
        <autumn-campaign-banner />
      </template>
    </experiment-toggle>
    <experiment-toggle name="merChristmasCampaign">
      <template #a />
      <template #b>
        <christmas-campaign-banner />
      </template>
    </experiment-toggle>
    <Highlight />
    <Header />
    <main>
      <Nuxt />
    </main>
    <client-only>
      <noscript>
        <iframe
        :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}&noscript=`"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
        ></iframe>
      </noscript>
    </client-only>
    <Footer />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Header from '~/components/header/Header.vue';
import Highlight from '~/components/Highlight.vue';
import { getInstance } from '~/services/bffService';
import Footer from '~/components/core/shared/footer/Footer.vue';
import ExperimentToggle from '~/components/experiments/ExperimentToggle.vue';
import AutumnCampaignBanner from '~/components/experiments/be-619-autumn-campaign/AutumnCampaignBanner.vue';
import ChristmasCampaignBanner from '~/components/experiments/be-693-Christmas-campaign/ChristmasCampaignBanner.vue';
import SpringCampaignBanner from '~/components/experiments/be-865-spring-campaign/SpringCampaignBanner.vue';

export default {
  components: {
    Header,
    Footer,
    Highlight,
    ExperimentToggle,
    AutumnCampaignBanner,
    ChristmasCampaignBanner,
    SpringCampaignBanner
  },

  data() {
    return {
      navShown: false,
      gtmKey: undefined,
      countryVat: {},
    };
  },

  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
        type: 'text/javascript',
      };
      script.push(attrs);
    }

    return {
      script,
    };
  },

  computed: {
    language() {
      return this.$store.state.language;
    },
    host() {
      return this.$paths.host ?? '';
    },
    airport() {
      return this.$store.state.airport;
    },
    merchants() {
      return this.$store.state.merchants;
    },
    merchant() {
      return this.$store.state.merchant;
    },
    alternativeMerchant() {
      return this.$store.state.alternativeMerchant;
    },
    searchResults() {
      return this.$store.state.searchResults;
    },
    minPrice() {
      if (!this.searchResults.length) return 0;
      const min = this.searchResults.reduce((prev, curr) => (prev.price < curr.price ? prev : curr));
      return min.price;
    },
    maxPrice() {
      if (!this.searchResults.length) return 0;
      const max = this.searchResults.reduce((prev, curr) => (prev.price > curr.price ? prev : curr));
      return max.price;
    },
    minPriceExVat() {
      if (!this.searchResults.length) return 0;
      return this.minPrice - (+this.countryVat / 100) * this.minPrice;
    },
    maxPriceExVat() {
      if (!this.searchResults.length) return 0;
      return this.maxPrice - (+this.countryVat / 100) * this.maxPrice;
    },
    searchResultsParkingType() {
      return this.searchResults
        .map((searchResult) => searchResult.parking_type)
        .toString();
    },
    merchantsIds() {
      return this.$store.state.merchants
        .map((merchant) => merchant.id)
        .toString();
    },
    dates() {
      return this.$store.state.dates;
    },
    dayCount() {
      if (!this.dates || !this.dates.arrival || !this.dates.departure) return;
      // eslint-disable-next-line consistent-return
      return (
        Math.abs(
          dayjs(this.dates.departure).diff(this.dates.arrival, 'days'),
        ) + 1
      );
    },
  },
  async mounted() {
    const bff = getInstance('parkos', this.$config);

    const countryVat = await bff.getCountryVat(this.language?.country.code);
    this.countryVat = countryVat?.[0].vat;

    setTimeout(() => {
      this.loadGTM();
    }, 1000);
  },
  created() {
    this.gtmKey = this.$store.state.language.gtm_key;
  },

  methods: {
    loadGTM() {
      const {
        gtmKey,
        airport,
        merchantsIds,
        searchResultsParkingType,
        dayCount,
        dates,
        merchant,
        searchResults,
        host,
        minPrice,
        minPriceExVat,
        maxPrice,
        maxPriceExVat,
      } = this;

      if (gtmKey) {
        /* eslint-disable */
        // @ts-ignore
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          j.type = 'text/javascript'
          f.parentNode.insertBefore(j, f);
          // pushing data layer
          window.dataLayer.push({
            airportDevTitle: airport?.devtitle,
            airportSlug: airport?.slug,
            airportName: airport?.name,
            merchantIds: merchantsIds,
            parkingTypes: searchResultsParkingType,
            productTypes: '965so8,1032so8,409so8,391so8,525so8,509so8',
            days: dayCount,
            minPrice: minPrice,
            minPriceExVat: minPriceExVat,
            maxPrice: maxPrice,
            maxPriceExVat: maxPriceExVat,
            arrival: dates?.arrival,
            departure: dates?.departure,
            searchResults: searchResults,
            merchantSlug: merchant?.slug,
            merchantId: merchant?.id,
            pageType: 'merchants',
            domainName: host,
            token: 'ss5wJUIeEJDIwN4aH88N49KziAoJKogPrnKbTSmK',
          });
        };
        if (typeof tagManagerDelay === 'undefined') {
          window.loadTagManager(window, document, 'script', 'dataLayer');
        }
        /* eslint-enable */
      }
    },

    navToggle(value) {
      this.navShown = value;
    },
  },
};
</script>

<style>
* {
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
