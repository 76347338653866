<template>
  <Dropdown
    key="languages-dropdown"
    class="pr-0 -mr-6 border-b-0"
    @toggled="handleToggle"
  >
    <template #button>
      <div class="flex items-center w-full sm:w-auto">
        <Flag :lang="language.lang" />
        {{ language.lang.split('-')[0] | uppercase }}
        <span class="hidden ml-1 sm:block caret" />
        <IconCaretRight class="ml-auto text-black-alt-500 sm:hidden" />
      </div>
    </template>
    <template #content>
      <ul class="sm:py-2 sm:-ml-2">
        <li
          v-for="item in herflangs"
          v-show="item.lang !== language.lang"
          :key="`lang-${item.lang}`"
        >
          <a
            :href="`//${item.domain}/${item.slug}/${merchantSlug}.html`"
            class="flex items-center py-3 px-6 text-base text-gray-600 hover:text-black hover:no-underline whitespace-nowrap border-b border-athens sm:py-1 sm:px-4 sm:text-black sm:hover:bg-gray-200 sm:border-none"
          >
            <Flag :lang="item.lang" />
            {{ languageByLang(item.lang) }}
          </a>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '~/components/header/Dropdown.vue';
import Flag from '~/components/icons/Flag.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';

export default {
  filters: {
    uppercase: (value) => value.toUpperCase(),
  },

  components: {
    Dropdown,
    Flag,
    IconCaretRight,
  },

  computed: {
    merchantSlug() {
      return this.$store.state.merchantSlug;
    },
    language() {
      return this.$store.state.language;
    },
    herflangs() {
      return this.$store.state.herflangs || [];
    },
    languages() {
      return this.$store.state.languages;
    },
  },
  methods: {
    languageByLang(lang) {
      return this.languages.find((e) => e.lang === lang)?.native_name;
    },
    handleToggle(expanded) {
      this.$emit('toggled', expanded);
    },
  },
};
</script>
