<template>
  <div class="search-expression relative">
    <div
      class="absolute inset-y-1/2 left-0 pointer-none h-full flex top-0 items-center"
    >
      <Magnifier class="search-expression__magnifier absolute transition-all duration-200 ml-3 w-7 h-7 min-w-min" :class="{'search-expression__magnifier--hide':!isClear}" />
      <div
        role="button"
        tabindex="0"
        class="search-expression__clear transition-opacity inline-flex absolute place-content-between flex-col cursor-pointer h-4 p-4 box-content duration-200"
        :class="{'opacity-0':isClear,'opacity-100':!isClear}"
        @keypress.stop.prevent="clear"
        @click.stop.prevent="clear"
      >
        <div class="relative w-5 h-0.5 bg-black rounded transition-transform duration-200 transform origin-left" :class="{'rotate-45':!isClear}" />
        <div class="relative w-5 h-0.5 bg-black rounded transition-transform duration-200 transform origin-left" :class="{'-rotate-45':!isClear}" />
      </div>
    </div>
    <input
      :value="expression"
      class="mega-menu__search__input w-full pl-12 p-3 border border-athens focus:outline-none"
      :class="{'border-l-0 border-r-0 rounded-none':borderYOnly,'rounded':!borderYOnly}"
      type="text"
      :placeholder="placeholder || $i18n('general.search')"
      aria-label="search"
      @input="handleSearch"
    >
  </div>
</template>

<script>
import Magnifier from '~/components/icons/IconMagnifier.vue';

export default {
  components: {
    Magnifier,
  },
  props: {
    expression: {
      type: String,
      required: false,
      default: () => '',
    },
    placeholder: {
      type: String,
      required: false,
      default: () => undefined,
    },
    borderYOnly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    isClear() {
      return !this.expression || this.expression === '';
    },
  },
  methods: {
    handleSearch(event) {
      this.$emit('expressionChanged', event.target.value);
    },
    clear() {
      this.$emit('expressionChanged', '');
    },
  },
};
</script>

<style lang="scss">
.search-expression{
  &__magnifier{
    &--hide{
      transform:rotate3d(1,1,0,90deg);
    }
  }
}
</style>
